import React, { Component } from "react"
import { Link } from "gatsby"

import styles from "./header.module.scss"

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const showMenu = !this.state.showMenu
    this.setState({ showMenu: showMenu })
  }

  render() {
    return (
      <header>
        <div className={styles.mainHeader}>
          <div className={styles.responsiveMenu} onClick={this.handleClick}>
            <p>Menu</p>
            <i className={`fa fa-bars ${styles.menuIcon}`} />
          </div>
          <div id="header-navigation">
            <nav>
              <div>
                <ul
                  className={`${styles.menu} ${
                    this.state.showMenu ? `${styles.menuActive}` : ""
                  }`}
                >
                  <li className={styles.menuItem}>
                    <Link to="/" className={styles.menuItemLink}>
                      <i className="fa fa-user" />
                      <span className="menu-title">About</span>
                    </Link>
                  </li>

                  <li className={styles.menuItem}>
                    <Link to="/presentations" className={styles.menuItemLink}>
                      <i className="fa fa-file-text-o" />
                      <span className="menu-title">Presentations</span>
                    </Link>
                  </li>

                  <li className={styles.menuItem}>
                    <Link to="/publications" className={styles.menuItemLink}>
                      <i className="fa fa-archive" />
                      <span className="menu-title">Publications</span>
                    </Link>
                  </li>

                  {/*<li className={styles.menuItem}>*/}
                  {/*  <Link to="/cfp" className={styles.menuItemLink}>*/}
                  {/*    <i className="fa fa-volume-up"/>*/}
                  {/*    <span className="menu-title">CFP</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}

                  <li className={styles.menuItem}>
                    <Link to="/uses" className={styles.menuItemLink}>
                      <i className="fa fa-gear" />
                      <span className="menu-title">Uses</span>
                    </Link>
                  </li>

                  <li className={styles.menuItem}>
                    <Link to="/contact" className={styles.menuItemLink}>
                      <i className="fa fa-map-marker" />
                      <span className="menu-title">Contact</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}
