import React, { Component } from "react"
import ProfilePicture from "../profile-picture/profile-picture"

import styles from "./profile.module.scss"

export default class Profile extends Component {
  render() {
    return (
      <>
        <div className={styles.profileImage}>
          <ProfilePicture />
        </div>
        <div className={styles.profileInfo}>
          <div className={styles.nameJob}>
            <h1 className={styles.name}>Łukasz Rybka</h1>
            <span className={styles.job}>Full-Stack Developer</span>
          </div>
          <div className={styles.socialIcons}>
            <a
              className={styles.socialIcon}
              href="https://pl.linkedin.com/in/smoczysko"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin" />
            </a>
            <a
              className={styles.socialIcon}
              href="https://github.com/Smoczysko"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-github-alt" />
            </a>
            <a
              className={styles.socialIcon}
              href="https://twitter.com/Smoczysko"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter" />
            </a>
            <a
              className={styles.socialIcon}
              href="https://www.facebook.com/lukrybka"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook-f" />
            </a>
            <a
              className={styles.socialIcon}
              href="https://www.instagram.com/smoczysko88/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram" />
            </a>
          </div>
        </div>
      </>
    )
  }
}
