import React, { Component } from "react"

import styles from "./footer.module.scss"

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className={styles.footer}>
          <p className={styles.copyright}>
            Copyright © Łukasz Rybka 2019 All rights reserved
          </p>
          <div className={`row ${styles.noMargin}`}>
            <div className="col-md-3 offset-1">
              <div className={styles.footerTitle}>Site</div>
              <ul className={styles.footerLinksList}>
                <li>
                  <a href="/">About</a>
                </li>
                <li>
                  <a href="/presentations">Presentations</a>
                </li>
                <li>
                  <a href="/publications">Publications</a>
                </li>
                <li>
                  <a href="/uses">Uses</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 offset-1">
              <div className={styles.footerTitle}>Additional</div>
              <ul className={styles.footerLinksList}>
                <li>
                  <a href="/projects">Projects</a>
                </li>
                <li>
                  <a href="/podcasts">Podcasts</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 offset-1">
              <div className={styles.footerTitle}>Legal</div>
              <ul className={styles.footerLinksList}>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/terms-of-service">Terms of Service</a>
                </li>
                <li>
                  <a href="/cookie-policy">Cookie Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
