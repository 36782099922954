import React from "react"
import { StaticQuery, graphql } from "gatsby"

import SEO from "../seo"
import Profile from "../profile/profile"
import Header from "../header/header"
import Footer from "../footer/footer"

import styles from "./layout.module.scss"

const Layout = ({ children, title }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div id="wrapper" className={styles.wrapper}>
        <SEO title="Home" />
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Profile />
            </div>
            <div className="col-md-9">
              <Header />

              <div className={styles.contentWrap}>
                <div className={styles.pageTitle}>
                  <div className={styles.pageTitleText}>
                    <h2>{title}</h2>
                  </div>
                </div>
              </div>
              <div className={styles.contentWrapper}>{children}</div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default Layout
